<template>
  <div>
    <a @click="$router.push({ name: 'staff-payment-requests' })">
      <feather-icon
        icon="ArrowLeftIcon"
        class="cursor-pointer align-bottom"
      />Back to list
    </a>
    <vx-card class="mt-5">
      <h4 class="font-bold">Payment request details</h4>
      <vs-row>
        <vs-col vs-w="4" vs-xs="12">
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Customer name</label>
            <span class="font-bold">{{ paymentRequestDetail.payeeName }}</span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Customer mobile</label>
            <span class="font-bold">{{
              paymentRequestDetail.payeePhone | phoneNumber
            }}</span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Customer email</label>
            <span class="font-bold"
              ><a :href="`mailto:${paymentRequestDetail.payeeEmail}`">{{
                paymentRequestDetail.payeeEmail
              }}</a></span
            >
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Your reference</label>
            <span class="font-bold">
              {{ paymentRequestDetail.reference }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Amount</label>
            <span class="font-bold">
              {{ moneyFormat(paymentRequestDetail.amount) }}
            </span>
          </div>
        </vs-col>
        <vs-col vs-w="4" vs-xs="12">
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Request type</label>
            <span class="font-bold">
              {{
                paymentRequestDetail.requestType
                  ? paymentRequestDetail.requestType
                  : "one-off" | capitalize
              }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Account</label>
            <span class="font-bold">
              {{
                paymentRequestDetail.bank
                  ? paymentRequestDetail.bank.accountName
                  : "-"
              }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Request sent</label>
            <span class="font-bold">
              {{ moment(paymentRequestDetail.createdAt) }}
            </span>
          </div>
          <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Status</label>
            <span class="font-bold">
              {{ paymentRequestDetail.paymentStatus }}
            </span>
          </div>
                <div class="flex flex-wrap items-center content-between wrap-span">
            <label>Status</label>
            <span class="font-bold">
              {{ paymentRequestDetail.paymentStatus }}
            </span>
          </div>
        </vs-col>
        <vs-col vs-w="4" vs-xs="12">
          <div
            v-if="paymentRequestDetail.paymentType"
            class="flex flex-wrap items-center content-between wrap-span"
          >
            <label>Payment type</label>
            <span class="font-bold">
              {{ paymentRequestDetail.paymentType }}
            </span>
          </div>
          <div
            v-if="paymentRequestDetail.salesDate"
            class="flex flex-wrap items-center content-between wrap-span"
          >
            <label>Sales date</label>
            <span class="font-bold">
              {{ moment(paymentRequestDetail.salesDate) }}
            </span>
          </div>
          <div
            v-if="paymentRequestDetail.program"
            class="flex flex-wrap items-center content-between wrap-span"
          >
            <label>Program</label>
            <span class="font-bold">
              {{ paymentRequestDetail.program }}
            </span>
          </div>
          <div
            v-if="paymentRequestDetail.files && paymentRequestDetail.files.length"
            class="flex flex-wrap items-center content-between wrap-span"
          >
            <label>Attached files: </label>
            <ul>
              <li v-for="(file, key) in paymentRequestDetail.files" :key="key">
                <a :href="`${baseUrl}uploads/${file.name}`" target="_blank">{{
                  file.name
                }}</a>
                <span class="ml-10" @click="removeFile(file, key)">X</span>
              </li>
            </ul>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>

    <vx-card class="mt-10">
      <h4 class="font-bold">Transaction linked to this payment request</h4>
      <vs-table
        v-if="paymentRequestDetail"
        :data="paymentRequestDetail.transactions"
        :max-items="10"
      >
        <template slot="thead">
          <vs-th>
            Date
          </vs-th>
          <vs-th>
            Transaction reference
          </vs-th>
          <vs-th>
            Amount
          </vs-th>
          <vs-th>
            Status
          </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.createdAt">
              {{ moment(tr.createdAt) }}
            </vs-td>
            <vs-td :data="tr.transactionID">
              {{ tr.transactionID }}
            </vs-td>
            <vs-td :data="tr.amount">
              {{ moneyFormat(tr.amount) }}
            </vs-td>
            <vs-td :data="tr.payeeName">
              {{ paymentRequestDetail.paymentStatus }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

import "sweetalert2/src/sweetalert2.scss";
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      paymentTransactions: [],
      paymentRequestDetail: ""
    };
  },
  methods: {
    ...mapActions("paymentRequest", [
      "fetchPaymentRequestDetailById",
      "removeFileFromPaymentRequestByIdAndFileId"
    ]),
    async fetchDetail(id) {
      await this.fetchPaymentRequestDetailById(id).then(result => {
        this.paymentRequestDetail = result.data.data;
      });
    },
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    removeFile(file, key) {
      let self = this;
      Swal.fire({
        title: "Are you sure?",
        text: "The file will be permanently deleted.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it"
      }).then(result => {
        if (result.value) {
          self.removeFileConfirmation(file, key);
        }
      });
    },
    async removeFileConfirmation(file, key) {
      await this.removeFileFromPaymentRequestByIdAndFileId({
        id: this.$route.params.id,
        fileId: file._id,
        fileName: file.name
      })
        .then(result => {
          if (result) {
            this.showMessage(
              "Success",
              "File has been removed successfully.",
              "success",
              "icon-check-circle"
            );
            this.paymentRequestDetail.files.splice(key, 1);
          }
        })
        .catch(err => {
          this.showMessage("Error", err.message, "danger", "icon-times");
        });
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,

      });
    }
  },
  mounted() {
    this.fetchDetail(this.$route.params.id);
  }
};
</script>
